.navbar-container {
    /* temp !!! */
    /* margin: 200px auto 100px auto; */
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    /* height: 56px; */
    height: 60px;
    /* width: 800px; */
    width: 100%;
    background-color: var(--dark);
    border: 1px solid var(--border-dark);
    box-shadow: 3px 3px 6px var(--dark-shadow), -3px -3px 6px var(--light-shadow);
    border-radius: 0px 0px 5px 5px;
}

.navbar-full {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

@media (max-width: 425px) {
    .navbar-full {
        flex-wrap: wrap;
        justify-content: center;
    }
    .navbar-container {
        height: 100%;
        margin-bottom: 10px;
    }
    .navbar-navigation {
        flex-wrap: wrap;
        justify-content: center;
        padding: 12px;
    }
}

.navbar-navigation {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar-navigation button {
    border-radius: var(--rounded-md);
}

a {
    border-radius: var(--rounded);
}

.coffee-icon {
    width: 20px;
    height: 20px;
}

.twitter-icon {
    width: 20px;
    height: 20px;
}

.logo {
    text-transform: uppercase;
    color: var(--warning);
}