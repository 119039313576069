/* .camera-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 80%;
}

/* @media (max-width: 425px) { */
.camera {
  min-height: 200px;
  /* width: 400px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
/* } */
@media (max-width: 425px) {
  .camera {
    min-height: 200px;
  }
}

.camera-player {
    display: flex;
    flex-direction: column;
    align-content: start;
}

.display {
}

.non-display {
  display: none!important;
}
