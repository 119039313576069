.home {
    color: #FDFFFC;
}


/* 
.cameras {
    display: inline-grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    width: 70vw;
    height: 100vh;
    place-items: center;
}

.start-cameras-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 100vh;
}

.chat {
    width: 30vw;
    height: 100vh;
} */

.camera-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: fit-content;
}