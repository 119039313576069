.chat {
    background-color: #1d2125;
    border-radius: 20px;
}

.mirko-wrapper {
    height: 80vh;
}

.avatar {
    width: 48px;
    height: 48px;
    object-fit: cover;
}