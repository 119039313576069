.button {
    /* display: flex; */
    box-sizing: border-box;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    font-family: "Rubik", sans-serif;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--rounded);
    /* test */
    cursor: pointer;
    outline: none;
    /* border: none; */
    height: 32px;
}

.button-text {
    font-size: 16px;
    min-width: 32px;
    width: fit-content;
    gap: 4px;
}

.button-icon {
    font-size: 14px;
    width: 32px;
}

.button-text {
    padding: 0 20px;
}

.button i {
    font-size: 12px;
}

.button-primary {
    background-color: var(--dark);
    color: var(--light);
    border: 1px solid var(--border-dark);
}

.button-secondary {
    background-color: var(--color);
    color: var(--light);
    border: transparent;
    font-weight: 500;
}

.button-secondary:active {
    color: var(--color);
    background-color: var(--dark);
}

.button-up,
.button-mono {
    box-shadow: 3px 3px 6px var(--dark-shadow), -3px -3px 6px var(--light-shadow);
}

.button-down,
.button-mono:active,
.button-mono-pushed {
    color: var(--color);
    box-shadow: inset 3px 3px 6px var(--dark-shadow), inset -3px -3px 6px var(--light-shadow);
}


/* .button-mono:active {
    color: var(--color);
} */

.button-mono:disabled {
    color: var(--light-disabled);
    border-color: var(--light-disabled);
    pointer-events: none;
    user-select: none;
}