:root {
    --dark: #272829;
    --light: #FDFFFC;
    --color: #db202c;
    --warning: #db202c;
    --dark-shadow: #151516;
    --light-shadow: #15151634;
    --light-disabled: #fdfffc3d;
    --border-dark: #db202c46;
    --rounded-sm: 8px;
    --rounded-md: 12px;
    --rounded: 50px;
}

.text-color {
    color: #FDFFFC;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}