.playbar-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    padding: 0px 20px;

    /* gap: 20px; */

    background-color: var(--dark);

    /* width should be responsive */
    height: 48px;
    /* width: 600px; */
    width: 100%;

    border-radius: var(--rounded-sm);

    /* temp !!! */
    /* margin: 100px auto 200px auto; */

    /* border: 1px solid var(--border-dark); */
    border: 1px solid var(--border-dark);

    /* bumpy up */
    box-shadow: 3px 3px 6px var(--dark-shadow),
        -3px -3px 6px var(--light-shadow);
}

@media (max-width: 425px) { 
    .playbar-container {
        padding: 0px 4px;
    }
    .playbar-left-section {
        gap: 18px!important;
        justify-content: space-around;
    }
}

.playbar-full {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

.playbar-left-section {
    display: flex;
    align-items: center;
    gap: 20px;
}

.playbar-right-section {
    display: flex;
    align-items: center;
    gap: 20px;
}